export const mapping = {
  user_rows: {
    Delete: "Delete",
    Comment: "Commentaire",
    Conventions: `Avez-vous encodé une convention correspondante dans l'onglet "Conventions"? (Oui/Non)`,
    // `Avez-vous encodé une convention correspondante dans l'onglet "Conventions"? (Oui/Non)`,
    Organisation: "Dénomination officielle de l'organisation",
    "Client or Polis Number": "N° de client ou n° de police",
    "BCE Number": "N° BCE",
    "Requestor Name": "Demandeur Nom",
    "Requestor First Name": "Demandeur Prénom",
    "Requestor Email": "Demandeur E-mail",
    Function: "Demandeur Fonction",
    "User Name": "Utilisateur Nom",
    "User First Name": "Utilisateur Prénom",
    "User Language": "Utilisateur Langue",
    "User Email": "Utilisateur E-mail",

    "Global Access": "Accès Global",
    "Local Acces Opt 1": "Accès restreint Gestion assurances de personnes",
    "Local Acces Opt 2": "Accès restreint Gestion assurances de choses",
    "Local Acces Opt 3":
      "Accès restreint Gestion sinistres assurances de personnes",
    "Local Acces Opt 4":
      "Accès restreint Gestion sinistres assurances de choses",
    Signature: "Signature à la dernière page? (oui/non)",
    "Date of Sign": "Date de la signature",
    "Signature Name": "Nom du signataire",
  },
  business_rows: {
    Delete: "Delete",
    Comment: "Commentaire",
    "BCE Number": "Numéro d'Entreprise (BCE)",
    "Organisation Adress": "Adresse officielle",
    "Phone Number": "Numéro de téléphone",
    "Organisation Email": "Adresse e-mail",
    "Representative Name": "Valablement représenté par Madame/Monsieur",
    "Representative Function": "Qui exerce la fonction de",
    "Client Number": "Numéro de client",
    "Organisation Group": "Dénomination du groupe",
    Organisation: "Dénomination officielle",
    "Assured Person Name": "Nom de l'assuré",
    "Assured Person Number": "N° de l'assuré",
    Signature: "Signature à la dernière page? (oui/non)",
    "Signature Date": "Date de la signature",
    "Signature Name": "Nom du signataire",
  },
};
