import React from "react";
import * as XLSX from "xlsx";
import { getDocs, collection, query, orderBy } from "firebase/firestore";
import { IconBrandOffice, IconDownload, IconPdf } from "@tabler/icons-react";
import { mapping } from "../Mapping";

function GenerateExcelFile({ firestore, startTime, endTime }) {
  const collectionName =
    process.env.REACT_APP_COLLECTION || "processed_data_prod_v5";
  // Function to map row keys to new keys according to the provided mapping
  const mapRowKeys = (row, mapping) => {
    return Object.keys(row).reduce((acc, key) => {
      const mappedKey = mapping[key]; // Use the mapped key if available, otherwise skip (not a target table column name)
      if (mappedKey && mappedKey !== "Delete") {
        // Exclude the delete button column
        acc[mappedKey] = row[key];
      }
      return acc;
    }, {});
  };

  // Function to sort the keys of a row according to the order in the mapping object
  const sortRowKeys = (mappedRow, mapping) => {
    const sortedRow = {};
    Object.keys(mapping).forEach((key) => {
      if (mapping[key] in mappedRow && mapping[key] !== "Delete") {
        sortedRow[mapping[key]] = mappedRow[mapping[key]];
      }
    });
    return sortedRow;
  };

  const fetchData = async () => {
    try {
      // Query documents where state is "inprocess" and last_edited > 3 minutes ago
      const querySnapshot = await getDocs(
        query(collection(firestore, collectionName), orderBy("date", "desc"))
      );
      const userData = [];
      const organizationData = [];

      // For each mail in the database
      querySnapshot.forEach((doc) => {
        console.log(doc.data().last_edited, startTime, endTime);
        
        if (
          !doc.data().info_in_manual_excel &&
          doc.data().state === "completed" &&
          doc.data().last_edited > startTime &&
          doc.data().last_edited < endTime
        ) {
          // Extract data
          const {
            sender,
            sender_mail,
            date,
            name,
            subject,
            body,
            last_edited,
            all_user_rows,
            all_business_rows,
            id,
            path
          } = doc.data();
          const milliseconds = last_edited.seconds * 1000 + last_edited.nanoseconds / 1000000;
          // Create an excel row for each user row
          console.log(new Date(milliseconds).toLocaleString())
          all_user_rows.forEach((userValue) => {
            const mappedUserRow = mapRowKeys(userValue, mapping["user_rows"]);
            const sortedUserRow = sortRowKeys(
              mappedUserRow,
              mapping["user_rows"]
            );

            userData.push({
              "Adresse e-mail de l'émetteur": sender_mail,
              "Date de l'email": date,
              "Veuillez copier/coller le contenu de l'email": body.replaceAll(
                /\r\n|\r/g,
                "\n"
              ),
              "Last edited": new Date(milliseconds).toLocaleString(), //ensure newlines are correct encoding for excel
              ...sortedUserRow,
              "id" : id,
              "initial_name" : path
            });
          });

          // Create an excel row for each business row
          all_business_rows.forEach((businessValue) => {
            const mappedBusinessRow = mapRowKeys(
              businessValue,
              mapping["business_rows"]
            );
            const sortedBusinessRow = sortRowKeys(
              mappedBusinessRow,
              mapping["business_rows"]
            );

            organizationData.push({
              "Adresse e-mail de l'émetteur": sender,
              "Date de l'email": date,
              "Veuillez copier/coller le contenu de l'email": body.replaceAll(
                /\r\n|\r/g,
                "\n"
              ), //ensure newlines are correct encoding for excel
              ...sortedBusinessRow,
            });
          });
        }
      });
      // Convert data to Excel format
      const userRowsWS = XLSX.utils.json_to_sheet(userData);
      const organizationRowsWS = XLSX.utils.json_to_sheet(organizationData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, userRowsWS, "UserRows");
      XLSX.utils.book_append_sheet(wb, organizationRowsWS, "OrganizationRows");

      // Save Excel file
      XLSX.writeFile(wb, "output.xlsx");
    } catch (error) {
      console.error("Error fetching data from Firestore:", error);
    }
  };

  return (
    <div>
      {" "}
      <button
        className="mt-16 w-64 line-clamp-1 h-8 text-nowrap bg-blue-500 text-white px-2 py-1 flex gap-2 m-2 items-center jext-center justify-start  rounded -50  font-bold"
        onClick={fetchData}
      >
        <IconDownload />
        <IconBrandOffice />
        Export Excel File
      </button>
    </div>
  );
}

export default GenerateExcelFile;
