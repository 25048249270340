import React, { useState, useEffect } from "react";
import {
  getDocs,
  collection,
  query,
  orderBy,
  updateDoc,
  doc,
} from "firebase/firestore";
import { IconDownload } from "@tabler/icons-react";
import GenerateExcelFile from "./GenerateExcelFile";
import DownloadBugged from "./BugDownload";
import { ProcessTimeVisualizer } from "../ProcessTimeVisualizer";
import { GeneratePDFsFolder } from "./GeneratePDFsFolder";
import { DateTimePicker } from "@mantine/dates";
import { Timestamp } from "@firebase/firestore";

function AdminPage({ firestore }) {
  const [distribution, setDistribution] = useState([]);
  const [distributionPdf, setDistributionPdf] = useState([]);
  const collectionName =
    process.env.REACT_APP_COLLECTION || "processed_data_prod_v5";
  const collectionR = collection(firestore, collectionName);

  const allStates = [
    "completed",
    "completed_manually",
    "inprocess",
    "skipped",
    "unprocessed",
    "bugged",
    "manually validated",
  ];

  const allStatesPdf = ["auto", "checked", "unprocessed", "no_attachments_yet"];

  useEffect(() => {
    const fetchDistribution = async () => {
      try {
        const q = query(collectionR, orderBy("state"));
        const querySnapshot = await getDocs(q);

        // Count occurrences of each state
        const countsByState = {};
        const countsByStatePdf = {};
        querySnapshot.forEach((doc) => {
          const state = doc.data().state;
          const pdfChecked = doc.data().pdf_check;

          if (!countsByState[state]) {
            countsByState[state] = 1;
          } else {
            countsByState[state]++;
          }
          if (!countsByStatePdf[pdfChecked]) {
            countsByStatePdf[pdfChecked] = 1;
          } else {
            countsByStatePdf[pdfChecked]++;
          }
        });

        // Convert countsByState object to array of objects for easier rendering
        const distributionDataByState = allStates.map((state) => {
          const count = countsByState[state] || 0;
          return { state, count };
        });

        setDistribution(distributionDataByState);

        const distributionDataByStatePdf = allStatesPdf.map((pdfCheckState) => {
          const count = countsByStatePdf[pdfCheckState] || 0;
          return { pdfCheckState, count };
        });

        setDistribution(distributionDataByState);
        setDistributionPdf(distributionDataByStatePdf);
      } catch (error) {
        console.error("Error fetching distribution:", error);
      }
    };

    fetchDistribution();
  }, [firestore]);

  const handleResetStates = async (states) => {
    // Display confirmation dialog
    const confirmed = window.confirm(
      `Are you sure you want to reset ${states} to unprocessed?`
    );

    if (confirmed) {
      // User confirmed, reset states
      await resetStatesToUnprocessed(states);
    }
  };

  const resetStatesToUnprocessed = async (states) => {
    // try {
    console.log(firestore);
    const q = query(collectionR);
    const querySnapshot = await getDocs(q);

    const updates = [];
    console.log("ici");
    querySnapshot.forEach((docu) => {
      try {
        const data = docu.data(); // Get the document data
        if (states.includes(data.state)) {
          // Check if state is "inprocess"
          const docRef = docu.ref; // Get reference to the document
          updates.push(updateDoc(docRef, { state: "unprocessed" })); // Update the state field to "unprocessed"
        }
      } catch (error) {
        console.log("error for ref " + docu.ref.path);
        console.error("Error resetting states:", error);
      }
    });
    // Execute all updates concurrently
    await Promise.all(updates);
    window.location.reload();
    console.log("All states reset to 'unprocessed'.");
    // } catch (error) {
    //   console.error("Error resetting states:", error);
    // }
  };

  const [startTime, setStartTime] = useState(new Date(Date.now(0) - 7 * 24 * 60 * 60 * 1000)); // Set to Unix epoch (zero time)
  const [endTime, setEndTime] = useState( new Date(Date.now()));

  return (
    <div className="w-full  h-screen flex flex-col items-center justify-center">
      <div className="">
        <div className="flex gap-32 flex-wrap">
          <div>
            <h2 className="text-2xl  font-bold">Distribution of Mail States</h2>
            <ul className="flex flex-col gap-4 mt-4">
              {distribution.map((item, index) => (
                <li key={index} className="flex gap-2 items-center">
                  <p
                    className={`font-bold text-sm px-2 rounded-xl ${
                      item.state === "completed" &&
                      "bg-green-200 text-green-500"
                    }
              ${
                item.state === "completed_manually" &&
                "bg-green-200 text-green-500"
              }
              ${item.state === "inprocess" && "bg-cyan-200 text-cyan-500"}
              ${item.state === "skipped" && "bg-purple-200 text-purple-500"}
              ${item.state === "unprocessed" && "bg-gray-200 text-gray-500"}
              ${item.state === "bugged" && "bg-red-200 text-red-500"}
              ${
                item.state === "manually validated" &&
                "bg-yellow-200 text-yellow-500"
              }              
              `}
                  >
                    {item.state}: {item.count} Mails
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <ProcessTimeVisualizer firestore={firestore} />
        <button
          className="mt-8 h-8 right-20 z-50 bg-red-200 text-red-500  px-2 py-1 flex gap-2 items-center justify-center  rounded font-bold"
          onClick={() => handleResetStates(["skipped", "inprocess"])}
        >
          Reset inprocess and skipped to unprocessed
        </button>
        <button
          className="mt-8 h-8 right-20 z-50 bg-red-200 text-red-500  px-2 py-1 flex gap-2 items-center justify-center  rounded font-bold"
          onClick={() => handleResetStates(["bugged"])}
        >
          Reset bug to unprocessed
        </button>
      </div>
      <p>Export period</p>
      <DateTimePicker
        clearable
        defaultValue={startTime}
        onChange={(e) => {
          e && setStartTime(Timestamp.fromDate(e));
        }}
        label="Pick start date and time"
        placeholder="Pick date and time"
      />
      <DateTimePicker
        clearable
        defaultValue={endTime}
        onChange={(e) => {
          e && setEndTime(Timestamp.fromDate(e));
        }}
        label="Pick end date and time"
        placeholder="Pick date and time"
      />
      <DownloadBugged
        startTime={startTime}
        endTime={endTime}
        firestore={firestore}
      />
      <GenerateExcelFile
        startTime={startTime}
        endTime={endTime}
        firestore={firestore}
      />
      <GeneratePDFsFolder
        startTime={startTime}
        endTime={endTime}
        firestore={firestore}
      />
    </div>
  );
}

export default AdminPage;
