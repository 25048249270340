import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "mantine-datatable";
import { IconKeyboard, IconMoodHappy } from "@tabler/icons-react";
import { logEvent } from "firebase/analytics";
import { mapping } from "./Mapping";

const columns = {
  user_rows: [
    "Delete",
    "Commentaire",
    `Avez-vous encodé une convention correspondante dans l'onglet "Conventions"? (Oui/Non)`,
    "Dénomination officielle de l'organisation",
    "N° de client ou n° de police",
    "N° BCE",
    "Demandeur Nom",
    "Demandeur Prénom",
    "Demandeur E-mail",
    "Demandeur Fonction",
    "Utilisateur Nom",
    "Utilisateur Prénom",
    "Utilisateur Langue",
    "Utilisateur E-mail",
    "Accès Global",
    "Accès restreint Gestion assurances de personnes",
    "Accès restreint Gestion assurances de choses",
    "Accès restreint Gestion sinistres assurances de personnes",
    "Accès restreint Gestion sinistres assurances de choses",
    "Signature à la dernière page? (oui/non)",
    "Date de la signature",
    "Nom du signataire",
  ],
  business_rows: [
    "Delete",
    "Commentaire",
    "Dénomination du groupe",
    "Dénomination officielle",
    "Numéro d'Entreprise (BCE)",
    "Adresse officielle",
    "Numéro de téléphone",
    "Adresse e-mail",
    "Valablement représenté par Madame/Monsieur",
    "Qui exerce la fonction de",
    "Numéro de client",
    "Nom de l'assuré",
    "N° de l'assuré",
    "Signature à la dernière page? (oui/non)",
    "Date de la signature",
    "Nom du signataire",
  ],
};

function swap(json) {
  var ret = {};
  for (var key in json) {
    ret[json[key]] = key;
  }
  return ret;
}

const reverseMap = swap(mapping);

function Seth(props) {
  // userOrBusinessRowPropertyName = "user_rows" vs. "business_rows"

  const data = props.data;

  const [trigger, setTrigger] = useState(false);

  const [table, setTable] = useState({});

  const infoKey = "all_" + props.userOrBusinessRowPropertyName;

  const [cellEditId, setCellEditId] = useState(0);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === "k") {
        event.preventDefault();
        if (data) {
          addRow();
          props.setData(data);
        }

        // Your action here
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array ensures this effect only runs on mount and unmount

  const handleKeyDown = (e, rowIndex, colIndex) => {
    let nextInputSelector;

    switch (e.key) {
      case "Enter":
      case "ArrowDown":
        // Move down: increase rowIndex
        nextInputSelector = `input[name="${colIndex + props.transpose}-${
          rowIndex + !props.transpose
        }"]`;
        break;
      case "ArrowUp":
        // Move up: decrease rowIndex, but not less than 0
        nextInputSelector =
          colIndex > 0
            ? `input[name="${colIndex - props.transpose}-${
                rowIndex - !props.transpose
              }"]`
            : null;
        break;
        // case "ArrowRight":
        //   // Move right: increase colIndex
        //   nextInputSelector = `input[name="${colIndex + !props.transpose}-${
        //     rowIndex + props.transpose
        //   }"]`;
        //   break;
        // case "ArrowLeft":
        //   // Move left: decrease colIndex, but not less than 0
        //   nextInputSelector =
        //     colIndex > 0
        //       ? `input[name="${colIndex - !props.transpose}-${
        //           rowIndex - props.transpose
        //         }"]`
        //       : null;
        break;
      default:
        // For any other key, do nothing
        return;
    }

    if (nextInputSelector) {
      e.preventDefault(); // Prevent the default action for navigation keys
      const nextInput = document.querySelector(nextInputSelector);
      console.log("Navigating to", nextInputSelector, nextInput);

      if (nextInput) {
        nextInput.focus();
      } else {
        // Handle edge cases, like when there's no input in the next row or column
        console.log("No input found for", nextInputSelector);
      }
    }
  };

  const [activeCaretPosition, setActiveCaretPosition] = useState(0);

  useEffect(() => {
    console.log("data state mutated", props.data);
  }, [props.data]);

  const addRow = () => {
    logEvent(props.analytics, "row_added", {
      docId: props.data.id,
    });

    props.setData((currentData) => {
      // Create an empty object with the right keys, each initialized to an empty string
      const newObject = Object.keys(
        mapping[props.userOrBusinessRowPropertyName]
      ).reduce((accumulator, currentKey) => {
        accumulator[currentKey] = "";
        return accumulator;
      }, {});
      newObject["id"] = "id" + Math.random().toString(16).slice(2);

      // Create a new array with the new object appended, avoiding direct mutation of the existing array
      const updatedRows = [
        ...currentData["all_" + props.userOrBusinessRowPropertyName],
        newObject,
      ];

      // Create a new data object to ensure React detects the change
      const newData = {
        ...currentData,
        ["all_" + props.userOrBusinessRowPropertyName]: updatedRows,
      };

      // Not only set the global rows but also the local rows
      newData.attachments[props.attachmentIdx][
        props.userOrBusinessRowPropertyName
      ] = [
        ...(newData.attachments[props.attachmentIdx][
          props.userOrBusinessRowPropertyName
        ] || []),
        newObject,
      ];

      newData["dataUpdateReason"] = "row-add";

      return newData;
    });
  };

  function isObject(object) {
    return object != null && typeof object === "object";
  }

  const deleteRow = (rowIndex) => {
    logEvent(props.analytics, "row_deleted", {
      docId: props.data.id,
    });

    const rowToDelete = {
      ...props.data["all_" + props.userOrBusinessRowPropertyName][rowIndex],
    };

    // Create a new array excluding the row at the specified index
    const updatedRows = props.data[
      "all_" + props.userOrBusinessRowPropertyName
    ].filter((_, index) => index !== rowIndex);

    // Create a new data object to ensure React detects the change
    const newData = {
      ...props.data,
      ["all_" + props.userOrBusinessRowPropertyName]: updatedRows,
    };

    // Not only set the global rows but also the local rows
    newData.attachments = newData.attachments?.map((attachment) => {
      const updatedAttachmentRows = attachment[
        props.userOrBusinessRowPropertyName
      ].filter((row) => row.id !== rowToDelete.id);
      return {
        ...attachment,
        [props.userOrBusinessRowPropertyName]: updatedAttachmentRows,
      };
    });

    newData["dataUpdateReason"] = "row-delete";

    // Update the state with the new data object
    props.setData(newData);

    console.log("updated data after row deletion", newData);
  };

  useEffect(() => {
    if (props.data) {
      const tempTable = {};
      // Empty the table when this item does not have data
      if (!props.data["all_" + props.userOrBusinessRowPropertyName]) {
        console.log("item missed property, setting empty table");
        columns[props.userOrBusinessRowPropertyName].forEach((colName) => {
          tempTable[colName] = [];
        });
        setTable(tempTable);
        // setTrigger(!trigger);
      }

      // Create the columns as lists in the table
      if (props.data["all_" + props.userOrBusinessRowPropertyName]) {
        // Only rerender table when state was updated because of new mail (not for value changes)
        // if (
        //   ["next-mail", "row-add", "row-delete"].includes(
        //     props.data.dataUpdateReason
        //   )
        // )
        {
          columns[props.userOrBusinessRowPropertyName].forEach((colName) => {
            tempTable[colName] = [];
          });

          // Add user rows to the column lists
          props.data["all_" + props.userOrBusinessRowPropertyName].forEach(
            (row, rowIndex) => {
              const alreadyPushedCols = [];

              Object.keys(row).forEach((key) => {
                let columnName =
                  mapping[props.userOrBusinessRowPropertyName][key] || key;
                if (tempTable[columnName] !== undefined) {
                  alreadyPushedCols.push(columnName);
                  tempTable[columnName].push({
                    val: row[key],
                    col_name: columnName,
                    edit_property: key,
                    row_idx: rowIndex,
                    orignal_row: row,
                  });
                }
              });
            }
          );

          setTable({ ...tempTable }); // setTrigger(!trigger);
        }
      }
    }
  }, [props.data, props.data && props.data[infoKey], trigger]);

  // -------------------------------------
  const inputRef = useRef(null);

  const onCellChange = (e, rowIndex, keyname, cell, columnIndex, cellIndex) => {
    logEvent(props.analytics, "cell_edited", {
      docId: props.data.id,
    });
    props.setData((currentData) => {
      let updatedRows = [
        ...currentData["all_" + props.userOrBusinessRowPropertyName],
      ];

      let row = { ...cell.orignal_row };
      const originalRow = { ...row };

      // Update the keyname rawly to the row
      row[keyname] = e.target.value;

      // Do reverse map before writing row back to data
      const newrow = {};
      Object.keys(row).forEach((key) => {
        if (reverseMap[key]) {
          key = reverseMap[key];
        }
        newrow[key] = row[key];
      });

      updatedRows[rowIndex] = newrow;
      const newData = {
        ...currentData,
        ["all_" + props.userOrBusinessRowPropertyName]: updatedRows,
      };

      const localIdx = newData.attachments[props.attachmentIdx][
        props.userOrBusinessRowPropertyName
      ].findIndex((localrow) => localrow.id == newrow.id);

      // Not only set the global rows but also the local rows
      if (localIdx !== -1) {
        newData.attachments[props.attachmentIdx][
          props.userOrBusinessRowPropertyName
        ][localIdx] = newrow;
      } else {
        console.log(
          "row not found locally!",
          newData.attachments[props.attachmentIdx][
            props.userOrBusinessRowPropertyName
          ],
          newrow
        );
      }

      newData["dataUpdateReason"] = "cell-edit";
      newData["lastSelectCol"] = columnIndex;
      newData["lastSelectCell"] = cellIndex;
      newData["lastCaretPos"] = e.target.selectionStart;
      newData["lastCellVal"] = Date.now();

      setCellEditId(cellEditId + 1);

      return newData;
    });
  };
  function setCaret() {
    if (inputRef.current) {
      const caretPos = props.data["lastCaretPos"] || 0;
      inputRef.current.setSelectionRange(caretPos, caretPos);
    }
  }

  useEffect(() => {
    // Initialize the counter for the number of times setCaret has been called
    let count = 0;

    // Set up an interval to call setCaret 10 times at 100ms intervals
    const intervalId = setInterval(() => {
      setCaret();
      count += 1;

      // If setCaret has been called 10 times, clear the interval
      if (count >= 100) {
        clearInterval(intervalId);
      }
    }, 0.5);

    // Clean-up function to clear the interval when the component unmounts or dependencies change
    return () => clearInterval(intervalId);
  }, [cellEditId, inputRef.current]); // Dependencies array for useEffect

  return (
    <>
      {props.data && (
        <div className="mb-1 m-2 overflow-x-scroll">
          <div className="flex gap-4 items-center  max-w-md justify-between ">
            <h2 className="text-xl font-bold">{props.title}</h2>
            <button
              className="bg-blue-500 text-white px-2 py-1 flex gap-2 m-2 items-center justify-center border rounded font-bold"
              onClick={addRow}
            >
              Add row <IconKeyboard className="text-gray-200" />
            </button>
          </div>
          {data.info_in_manual_excel ||
            (data.state === "manually validated" && (
              <div className="flex gap-1 items-center text-sm font-bold text-green-400 bg-green-200 px-2 py-1 rounded">
                <IconMoodHappy size={44} /> This information was added mannually
                to the excel already. No need to check the information here.
              </div>
            ))}
          <div className={`border flex flex-wrap text-sm`}>
            <div
              className={`flex ${props.transpose ? "flex-col" : "flex-row"}`}
              key={trigger + JSON.stringify(table)}
            >
              {" "}
              {Object.keys(table).map((columnKey, columnIndex) => (
                <div
                  className={`flex  items-center ${
                    props.transpose ? "flex-row justify-between" : "flex-col"
                  }`}
                  key={columnKey + trigger}
                >
                  <div
                    className={`font-bold line-clamp-2 overflow-y-clip ${
                      props.transpose ? "h-6 w-64  line-clamp-1" : "h-12"
                    } border p-1`}
                  >
                    <p className=""> {columnKey}</p>
                  </div>
                  {columnKey != "Delete"
                    ? table[columnKey].map((cell, cellIndex) => (
                        <>
                          <input
                            ref={
                              columnIndex == props.data["lastSelectCol"] &&
                              cellIndex == props.data["lastSelectCell"]
                                ? inputRef
                                : null
                            }
                            key={cellIndex + cell.val}
                            name={`${columnIndex}-${cellIndex}`} // Add a name attribute to help identify the input
                            className="border h-6  text-gray-700"
                            value={cell.val}
                            onChange={(e) =>
                              onCellChange(
                                e,
                                cell.row_idx,
                                cell.edit_property,
                                cell,
                                columnIndex,
                                cellIndex
                              )
                            }
                            onKeyDown={(e) =>
                              handleKeyDown(e, cellIndex, columnIndex)
                            }
                            autoFocus={
                              columnIndex == props.data["lastSelectCol"] &&
                              cellIndex == props.data["lastSelectCell"]
                            }
                          />
                        </>
                      ))
                    : table[columnKey].map((cell, cellIndex) => (
                        <button
                          className={`px-3 h-6  text-white font-bold bg-red-400 rounded ${
                            props.transpose ? "w-32 mr-4" : ""
                          }`}
                          onClick={() => {
                            deleteRow(cellIndex);
                          }}
                          value={"Delete"}
                          key={cellIndex}
                        >
                          Delete
                        </button>
                      ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Seth;
