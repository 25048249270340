import React from "react";
import * as XLSX from "xlsx";
import { getDocs, collection, query, orderBy } from "firebase/firestore";
import { IconBrandOffice, IconDownload } from "@tabler/icons-react";

function DownloadBugged({ firestore, startTime, endTime }) {
  const collectionName =
    process.env.REACT_APP_COLLECTION || "processed_data_prod_v5";

  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(
        query(collection(firestore, collectionName), orderBy("date", "desc"))
      );
      const buggedIds = [];

      querySnapshot.forEach((doc) => {
        const { state, last_edited } = doc.data();
        if (
          state === "bugged"
          //  && last_edited > startTime &&
          //   last_edited < endTime
        ) {
          buggedIds.push({ "Bugged IDs": doc.id }); // Push an object with the required property name
        }
      });

      // Convert bugged IDs to Excel format
      const ws = XLSX.utils.json_to_sheet(buggedIds); // Pass the array of objects
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Bugged IDs");

      // Save Excel file
      XLSX.writeFile(wb, "bugged_ids.xlsx");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {" "}
      <button
        className="mt-16 w-64 line-clamp-1 h-8 text-nowrap bg-blue-500 text-white px-2 py-1 flex gap-2 m-2 items-center jext-center justify-start  rounded -50  font-bold"
        onClick={fetchData}
      >
        <IconDownload />
        <IconBrandOffice />
        Download bugged
      </button>
    </div>
  );
}

export default DownloadBugged;
