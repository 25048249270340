import "./App.css";
import { useCallback, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { IconTransform, IconTable } from "@tabler/icons-react";

import { getDatabase, onValue, ref, set } from "firebase/database";
import Antoine from "./Antoine";
import Seth from "./Seth";
import AdminPage from "./components/AdminPage";
import { getAnalytics } from "firebase/analytics";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC8-vcjtKuW1NVPHo_S4boX8K2m_9Mhf_Q",
  authDomain: "ima-documentautomation.firebaseapp.com",
  projectId: "ima-documentautomation",
  storageBucket: "ima-documentautomation.appspot.com",
  messagingSenderId: "708343281534",
  appId: "1:708343281534:web:cc51103300ad8d93f5e52f",
  databaseURL:
    "https://ima-documentautomation-default-rtdb.europe-west1.firebasedatabase.app/",
  measurementId: "G-DF959X15G1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const firestore = getFirestore(app);
const analytics = getAnalytics(app);

function App() {
  const [rowData, setRowData] = useState([]);
  const [columnNames, setColumnNames] = useState([]); // State to store column names
  const [data, setData] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("NL");
  const [transpose, setTranspose] = useState(true);
  const [dynamic, setDynamic] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  let [attachmentIdx, setAttachmentIdx] = useState(null);
  const [changeMailAllowed, setChangeMailAllowed] = useState(true);

  useEffect(() => {
    if (window.location.href.includes("admin")) {
      setIsAdmin(true);
    }
  }, [window?.location?.href]);

  // Function to handle language change
  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleInputChange = (rowIndex, cellIdx, newValue) => {
    const column = columnNames[cellIdx]; // Retrieve the relevant column name using cell index

    const updatedRow = { ...rowData[rowIndex], [column]: newValue };
    setRowData(
      rowData.map((row, idx) => (idx === rowIndex ? updatedRow : row))
    );

    // Update Firebase
    const updateRef = ref(database, `/init_spreadsheet/${column}/${rowIndex}`);
    set(updateRef, newValue);
  };

  useEffect(() => {
    const itemsRef = ref(database, "/init_spreadsheet");
    const browserLanguage = navigator.language;

    // Set the default language based on the browser's language
    setSelectedLanguage(browserLanguage);
    console.log("language   " + browserLanguage);
    onValue(itemsRef, (snapshot) => {
      const data = snapshot.val();
      const columns = Object.keys(data); // Assuming first row to be column names
      setColumnNames(columns); // Store column names in state

      const maxRows = Math.max(
        ...columns.map((key) => Object.keys(data[key]).length)
      );
      const rows = [columns]; // Initialize rows with column names

      for (let rowIndex = 0; rowIndex < maxRows; rowIndex++) {
        const row = columns.map((column) => data[column][rowIndex] || ""); // Collect values from each column for the row
        rows.push(row);
      }

      setRowData(rows);

      if (window.location.href.includes("admin")) {
        setIsAdmin(true);
      }
    });
  }, []);

  return (
    <div>
      {isAdmin ? (
        <AdminPage firestore={firestore}></AdminPage>
      ) : (
        <div className={`${transpose ? "flex" : ""}`}>
          <div className={`${transpose ? "w-1/2 xl:w-1/3" : ""}`}>
            <div className="flex gap-2 mt-2 ml-2">
              <select
                id="languageSelect"
                value={selectedLanguage}
                onChange={handleLanguageChange}
                className=" h-8 top-2 left-2 z-50 bg-gray-200 p-2 rounded"
              >
                <option value="NL">NL</option>
                <option value="FR">FR</option>
                <option value="DE">DE</option>
              </select>
              <button
                className=" top-2 h-8 left-20 z-50 bg-blue-500 text-white px-2 py-1 flex gap-2 items-center justify-center border rounded font-bold"
                onClick={() => setTranspose(!transpose)}
              >
                <IconTransform className="text-gray-200" />
              </button>
              <button
                className=" top-2 h-8 left-20 z-50 bg-blue-500 text-white px-2 py-1 flex gap-2 items-center justify-center border rounded font-bold"
                onClick={() => setDynamic(!dynamic)}
              >
                <IconTable className="text-gray-200" />
              </button>{" "}
            </div>
            <Seth
              title={"User Access Requests"}
              firestore={firestore}
              database={database}
              app={app}
              data={data}
              setData={setData}
              userOrBusinessRowPropertyName="user_rows"
              selectedLanguage={selectedLanguage}
              transpose={transpose}
              dynamic={dynamic}
              analytics={analytics}
              attachmentIdx={attachmentIdx}
              setAttachmentIdx={setAttachmentIdx}
              changeMailAllowed={changeMailAllowed}
              setChangeMailAllowed={setChangeMailAllowed}
            />
            <Seth
              title={"Business Access Requests"}
              firestore={firestore}
              database={database}
              app={app}
              data={data}
              setData={setData}
              userOrBusinessRowPropertyName="business_rows"
              selectedLanguage={selectedLanguage}
              transpose={transpose}
              dynamic={dynamic}
              analytics={analytics}
              attachmentIdx={attachmentIdx}
              setAttachmentIdx={setAttachmentIdx}
              changeMailAllowed={changeMailAllowed}
              setChangeMailAllowed={setChangeMailAllowed}
            />
          </div>
          <div className={`${transpose ? "w-1/2 xl:w-2/3" : ""}`}>
            <Antoine
              firestore={firestore}
              database={database}
              app={app}
              data={data}
              setData={setData}
              selectedLanguage={selectedLanguage}
              transpose={transpose}
              dynamic={dynamic}
              analytics={analytics}
              attachmentIdx={attachmentIdx}
              setAttachmentIdx={setAttachmentIdx}
              changeMailAllowed={changeMailAllowed}
              setChangeMailAllowed={setChangeMailAllowed}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
