import React, { useState, useEffect } from "react";
import { getFirestore, collection, query, getDocs } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import JSZip from "jszip";
import { IconDownload, IconPdf } from "@tabler/icons-react";

const getAttachmentType = (attachment) => {
  // This is explicitly set by the user
  if (attachment.doctype) return attachment.doctype;

  //   Legacy document types from old api (api-inferred)
  const document_type_to_doctype_map = {
    "Business Access Request": "Business",
    "User Access Request": "User",
    "Other Access Request": "Other",
  };
  if (attachment.document_type)
    return document_type_to_doctype_map[attachment.document_type];

  // Back-up, check user rows and business rows to infer doctype (not available for manually parsed)
  if (attachment.user_rows?.length > 0) return "User";
  if (attachment.business_rows?.length > 0) return "Business";
  return "Other";
};

const getBusinessOrUserRows = (data) => {
  const userRows = data["all_user_rows"];
  const businessRows = data["all_business_rows"];

  // Check if userRows exists and has length greater than 0
  if (Array.isArray(userRows) && userRows.length > 0) {
    return userRows;
  }

  // Check if businessRows exists and has length greater than 0
  if (Array.isArray(businessRows) && businessRows.length > 0) {
    return businessRows;
  }

  // Return an empty array if neither condition is met
  return [];
};

const generatePDFName = (name, type, id) => {
  if (!name) {
    return "";
  }
  let typeString = "";
  if (type === "User") typeString = "user";
  else if (type === "Business") typeString = "convention";
  else if (type === "Business & User")
    typeString = "convention_user"; // Handling "Business & User" case
  else if (type === "Other") return "";

  return `${name}_${typeString}_${id}.pdf`;
};

export const GeneratePDFsFolder = (props) => {
  const firestore = getFirestore();
  const storage = getStorage();
  const collectionName = "processed_data_prod_v5";

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0.0);

  const fetchData = async () => {
    setLoading(true); // Set loading state to true when fetching data
    setProgress(0);
    const zipRenamed = new JSZip(); // For files where rename is not falsy
    const zipOtherFiles = new JSZip(); // For files where rename is falsy
    try {
      const querySnapshot = await getDocs(
        query(collection(firestore, collectionName))
      );
      let totalAttachments = 0;
      const downloadPromises = [];
      let completedAttachments = 0;
      querySnapshot.docs.forEach((doc) => {
        const data = doc.data();
        // Filter for finished pdf checks and date range
        if (
          ["checked", "auto"].includes(data.pdf_check)
           && data.last_edited > props.startTime &&
            data.last_edited < props.endTime
        ) {
          data?.attachments?.forEach((att, idx) => {
            totalAttachments++;
            console.log("att", att);
            const fileRef = ref(storage, att.path);
            const promise = getDownloadURL(fileRef)
              .then((url) => fetch(url))
              .then((response) => response.blob())
              .then((blob) => {
                // Pdf name is generated because if renamed == '' while the item was checked it means that the agent found the initial name good and never edited it
                const attachmentType = getAttachmentType(att);
                const rows = getBusinessOrUserRows(data);
                const name = rows.length > 0 ? rows[0]["Organisation"] : "";
                const pdfName = generatePDFName(name, attachmentType, idx);
                console.log("pdfname", pdfName);

                const nameProperty = att.renamed || att.rename || pdfName;
                var wasRenamed = false;
                if (nameProperty) wasRenamed = true;
                const fileName = nameProperty
                  ? nameProperty.endsWith(".pdf")
                    ? nameProperty
                    : `${nameProperty}.pdf`
                  : att.name;

                // Debugging output
                if (wasRenamed) {
                  zipRenamed.file(fileName, blob); // Add to renamed ZIP
                } else {
                  zipOtherFiles.file(fileName, blob); // Add to original ZIP
                  console.log(`Other file: ${fileName}, ${data.id}`);
                }

                completedAttachments++;
                setProgress((completedAttachments / totalAttachments) * 100);
              });
            downloadPromises.push(promise);
          });
        }
      });

      await Promise.all(downloadPromises);

      // Generate and save the ZIP for renamed files
      if (Object.keys(zipRenamed.files).length > 0) {
        const contentRenamed = await zipRenamed.generateAsync({ type: "blob" });
        saveAs(contentRenamed, "exported_renamed_pdfs.zip");
      }

      // Generate and save the ZIP for original files
      if (Object.keys(zipOtherFiles.files).length > 0) {
        const contentOriginal = await zipOtherFiles.generateAsync({
          type: "blob",
        });
        saveAs(contentOriginal, "exported_other_files.zip");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data or generating zip:", error);
      setLoading(false);
    }
  };

  const saveAs = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <button
        onClick={fetchData}
        className="mt-16 w-64 line-clamp-1 h-8 text-nowrap bg-red-500 text-white px-2 py-1 flex gap-2 m-2 items-center jext-center justify-start  rounded -50  font-bold"
      >
        <IconDownload />
        <IconPdf /> Export PDFs Folder
      </button>
      {loading && <progress value={progress} max={100} />}
    </>
  );
};
