import React, { useEffect, useId, useState } from "react";
import {
  getDocs,
  collection,
  query,
  where,
  orderBy,
  limit,
  updateDoc,
  doc,
  serverTimestamp,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  IconKeyboard,
  IconCheck,
  IconBackspace,
  IconArrowRight,
  IconBug,
  IconDownload,
  IconPdf,
} from "@tabler/icons-react";
import { NativeSelect } from "@mantine/core";

function Antoine(props) {
  // State for email text
  const [emailText, setEmailText] = useState("Example email text");
  // State for attachment
  let [attachments, setAttachments] = useState([]);
  let [attachment, setAttachment] = useState(null);
  let [length, setLength] = useState(0);

  const [filetype, setFiletype] = useState(null);
  const [docId, setDocId] = useState(null);
  const [historic, setHistoric] = useState([]);
  const [validateText, setValidateText] = useState("Get First Email");
  const [alreadyUndo, setAlreadyUndo] = useState(false);

  const firestore = props.firestore;
  const storage = getStorage();
  const collectionName =
    process.env.REACT_APP_COLLECTION || "processed_data_prod_v5";
  const processedDataCollectionRef = collection(firestore, collectionName);

  // Handler for attachment selection
  const handleAttachmentChange = (value) => {
    var localAttachmentIdx = props.attachmentIdx;
    if (attachments.length > 0) {
      if (props.attachmentIdx == null) {
        localAttachmentIdx = 0;
      } else {
        localAttachmentIdx = (props.attachmentIdx + value) % attachments.length;
      }
      console.log("setAttachmentId to ", localAttachmentIdx);

      if (localAttachmentIdx < 0) localAttachmentIdx = attachments.length - 1;
      const a = attachments[localAttachmentIdx];
      props.setAttachmentIdx(localAttachmentIdx);
      const path =
        attachments[localAttachmentIdx].path ||
        attachments[localAttachmentIdx].saved_path;
      manageNewAttachement(a.path || a.saved_path);
    }
  };

  const renderAttachment = () => {
    if (!attachment)
      return (
        <div>
          {" "}
          <strong>No Attachment With this email</strong>
        </div>
      );

    if (attachment.includes("image")) {
      return (
        <img
          src={attachment}
          alt="Attachment"
          style={{ maxWidth: "100%", maxHeight: "200px" }}
          className="border"
          key={attachment}
        />
      );
    } else if (filetype == "pdf") {
      return (
        <embed
          src={attachment}
          key={attachment}
          type="application/pdf"
          width="100%"
          height="600px"
          className="border flex-grow h-full "
        />
      );
    } else {
      return <p key={attachment}>{attachment.name}</p>;
    }
  };

  const manageNewAttachement = async (path) => {
    if (path) {
      const fileRef = ref(storage, path);
      const url = await getDownloadURL(fileRef);

      setAttachment(url);
      setFiletype(path.split(".")[path.split(".").length - 1].toLowerCase());
    }
  };

  const handleNewDoc = async (sampleDoc) => {
    const body = sampleDoc.data().body;
    console.log(sampleDoc.data());
    // We need to track why the state was updated, [new mail vs. edited values]
    const newData = sampleDoc.data();
    newData["dataUpdateReason"] = "next-mail";

    // Set ids for all objects (necessary for editing later)

    newData.attachments?.forEach((attachment) => {
      attachment.user_rows?.forEach(
        (row) => (row.id = "id" + Math.random().toString(16).slice(2))
      );
      attachment.business_rows?.forEach((row) => {
        row.id = "id" + Math.random().toString(16).slice(2);
        // Old items in firestore database do not have the "Organisation Group" in their business rows. This property was later added to the columns and the parser on IMA's request
        if (!row.hasOwnProperty("Organisation Group")) {
          console.log("adding org group");
          row["Organisation Group"] = "";
        }
      });
    });

    // Aggregate user and business rows from all attachments
    const aggregatedUserRows = newData.attachments?.reduce(
      (acc, attachment) => {
        return acc.concat(attachment.user_rows || []);
      },
      []
    );

    const aggregatedBusinessRows = newData.attachments?.reduce(
      (acc, attachment) => {
        return acc.concat(attachment.business_rows || []);
      },
      []
    );

    // Overwrite global rows with aggregated rows from attachments
    newData.all_user_rows = aggregatedUserRows;
    newData.all_business_rows = aggregatedBusinessRows;

    props.setData(newData);
    setDocId(sampleDoc.id);
    setEmailText(body);
    attachments = sampleDoc.data().attachments;
    setAttachments([...attachments]);
    setLength(attachments.length);
    console.log(sampleDoc.data().attachments);
    console.log(attachments);
    handleAttachmentChange(0);
  };

  const handleNextEmail = async () => {
    clearDocValues();

    const languages =
      props.selectedLanguage !== "FR"
        ? ["de", "nl", "other", "DE", "NL", "Other"]
        : ["fr", "FR"];

    try {
      const sampleDoc = await findDocumentToProcess(
        processedDataCollectionRef,
        languages
      );
      if (sampleDoc) {
        await setDocInProcessState(sampleDoc.id, sampleDoc);
        // Move to the next email
      }
    } catch (error) {
      console.error("Error retrieving/updating document:", error);
    }
  };

  const handleNextMailPDFToRename = async () => {
    if (docId) {
      console.log("saved pdf doc id " + docId);
      props.data.pdf_check = "checked";
      await updateDoc(doc(processedDataCollectionRef, docId), props.data);
    }
    clearDocValues();

    try {
      // Specify the collection from which you are querying

      const querySnapshot = await getDocs(
        query(
          processedDataCollectionRef,
          where("pdf_check", "==", "unprocessed"),
          where("state", "in", [
            "completed",
            "completed_manually",
            "manually validated",
          ]),
          limit(1)
        )
      );

      if (!querySnapshot.empty) {
        const sampleDoc = querySnapshot.docs[0]; // Access the first document
        if (sampleDoc.exists()) {
          // Check if the document exists
          await setDocInProcessState(sampleDoc.id, sampleDoc); // Pass sampleDocData which contains the actual document fields
        }
      } else {
        console.log("No matching documents.");
        window.alert(
          "Currently no documents that are validated and still need their pdfs checked. There might be unvalidated docs that still need their pdfs checked."
        );
      }
    } catch (error) {
      console.error("Error retrieving/updating document:", error);
    }
  };

  const findDocumentToProcess = async (collectionRef, languages) => {
    console.log("params", collectionRef, languages);
    let doc = await queryDocument(
      collectionRef,
      "inprocess",
      languages,
      true,
      "asc"
    );
    if (!doc) {
      console.log("no in process trying unprocessed");
      doc = await queryDocument(
        collectionRef,
        "unprocessed",
        languages,
        false,
        "asc"
      );
    }
    if (!doc) {
      console.log("no unprocessed trying skipped");
      doc = await queryDocument(
        collectionRef,
        "skipped",
        languages,
        false,
        "asc"
      );
    }
    if (!doc) {
      console.log("no skipped");
      doc = await queryDocument(
        collectionRef,
        ["skipped", "unprocessed"],
        false,
        false,
        "asc"
      );
    }
    return doc;
  };

  const queryDocument = async (
    collectionRef,
    state,
    languages,
    useTimeConstraint,
    order = "desc"
  ) => {
    const timeConstraint = useTimeConstraint
      ? where("last_edited", "<", new Date(Date.now() - 30 * 60000))
      : null;
    const stateConstraint = Array.isArray(state)
      ? where("state", "in", state)
      : where("state", "==", state);

    const languageConstraint =
      languages.length > 0 ? where("lang", "in", languages) : null;
    console.log(Array.isArray(languages));
    console.log(languageConstraint);
    const queryConstraints = [
      timeConstraint,
      stateConstraint,
      languageConstraint,
      orderBy("last_edited", order),
      limit(1),
    ].filter(Boolean); // Filter out any null constraints

    const querySnapshot = await getDocs(
      query(collectionRef, ...queryConstraints)
    );
    return !querySnapshot.empty ? querySnapshot.docs[0] : null;
  };

  function clearDocValues() {
    setAttachments([]);
    setAttachment(null);
    props.setAttachmentIdx(0);
    setFiletype(null);
    setDocId(null);
  }

  // Function to process a new mail retrieved form firestore further
  async function setDocInProcessState(
    selectedDocId,
    sampleDoc,
    surpressSetInprocess
  ) {
    // Immediately set the state to inprogress so not 2 users editing the same doc
    // Also track the editing start time and end time
    if (selectedDocId) {
      if (!surpressSetInprocess) {
        await updateDoc(doc(processedDataCollectionRef, selectedDocId), {
          last_edited: serverTimestamp(),
          state: "inprocess",
          inprogress_start_time: serverTimestamp(),
        });
      } else {
        console.log(
          "setting inprocess is surpressed because searching with id"
        );
      }
    }
    // TODO: this function does 2 different things, should just do 1 operation or 1 logical collection of operations
    // The code in this function after this comment needs to move up in the function calling tree to fix this
    if (sampleDoc == null) {
      console.log("No more data to process");
      window.alert(
        "Congrats ! No more data to process. \n Bravo !Il n'y a plus de mail à vérifier."
      );
    } else {
      handleNewDoc(sampleDoc);
    }
  }

  function cleanUndefinedValues(obj) {
    for (const key in obj) {
      if (obj[key] === undefined) {
        // Remove property if value is undefined
        delete obj[key];
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        // Recursively clean nested objects
        cleanUndefinedValues(obj[key]);
      }
    }
  }

  const handleComplete = async () => {
    setValidateText("Validate");
    if (docId) {
      console.log("saved doc id " + docId);
      props.data.last_edited = serverTimestamp();
      props.data.state = "completed";
      props.data.pdf_check = "checked";
      cleanUndefinedValues(props.data);
      props.data.inprogress_end_time = serverTimestamp();
      await updateDoc(doc(processedDataCollectionRef, docId), props.data);
      setHistoric((prevHistoric) => [...prevHistoric, docId]);
    }
    handleNextEmail();
  };

  const handleCompleteManually = async () => {
    setValidateText("Validate");
    if (docId) {
      console.log("saved doc id " + docId);
      props.data.last_edited = serverTimestamp();
      props.data.state = "completed_manually";
      props.data.pdf_check = "checked";
      cleanUndefinedValues(props.data);
      props.data.inprogress_end_time = serverTimestamp();

      await updateDoc(doc(processedDataCollectionRef, docId), props.data);
      setHistoric((prevHistoric) => [...prevHistoric, docId]);
    }
    handleNextEmail();
  };

  const handleBug = async () => {
    if (docId) {
      console.log("saved doc id as bugged " + docId);
      props.data.last_edited = serverTimestamp();
      props.data.state = "bugged";
      cleanUndefinedValues(props.data);
      await updateDoc(doc(processedDataCollectionRef, docId), props.data);
      setHistoric((prevHistoric) => [...prevHistoric, docId]);
    }
    handleNextEmail();
  };

  const handleUndo = async () => {
    console.log(historic);
    let internalDocId = docId;
    if (historic.length > 0) {
      setDocId(historic[historic.length - 1]);
      internalDocId = historic[historic.length - 1];
      setHistoric((prevHistoric) => prevHistoric.slice(0, -1));
    } else {
      window.alert(
        "No email to undo\nL'action undo n'est pas possible car aucun email n'a été corrigé\n"
      );
    }

    try {
      const documentRef = doc(processedDataCollectionRef, internalDocId);
      const documentSnapshot = await getDoc(documentRef);
      console.log("doc ID undo " + internalDocId);

      if (documentSnapshot.exists()) {
        setHistoric((prevHistoric) => prevHistoric.slice(0, -1));
        await updateDoc(doc(processedDataCollectionRef, docId), {
          last_edited: serverTimestamp(),
          state: "inprocess",
        });
        handleNewDoc(documentSnapshot);
      } else {
        console.log("Document does not exist");
        return null;
      }
    } catch (error) {
      console.error("Error getting document:", error);
      return null;
    }
  };

  const handleSkip = async () => {
    console.log("doc id skipped" + docId);
    await updateDoc(doc(processedDataCollectionRef, docId), {
      last_edited: serverTimestamp(),
      state: "skipped",
    });
    handleNextEmail();
  };

  const onIdChange = async (newId) => {
    let sampleDoc;

    try {
      // Query documents where state is "inprocess" and last_edited > 3 minutes ago
      const inProcessQuerySnapshot = await getDocs(
        query(processedDataCollectionRef, where("id", "==", newId))
      );

      // If no document is found, query the first document where state is ""
      let selectedDocId;

      if (inProcessQuerySnapshot.empty) {
        window.alert(
          "No document found with id: " + newId + " in " + collectionName
        );
      } else {
        clearDocValues();
        selectedDocId = inProcessQuerySnapshot.docs[0].id;
        sampleDoc = inProcessQuerySnapshot.docs[0];

        // Update the last edited timestamp of the selected document
        await setDocInProcessState(selectedDocId, sampleDoc, true);
        // Move to the next email
      }
    } catch (error) {
      console.error("Error retrieving/updating document:", error);
    }
  };

  return (
    <div
      className={`m-2  ${
        props.transpose && "flex flex-col justify-start h-full"
      }`}
    >
      <div
        className={`gap-2  ${
          props.transpose ? "flex" : "flex"
        }  items-center justify-between`}
      >
        <h2 className="text-2xl font-bold">Mail and Document</h2>{" "}
        <button
          className="line-clamp-1 h-8 text-nowrap bg-green-200 text-green-500 px-2 py-1 items-center flex gap-2 m-2 items-center jext-center justify-center  rounded -50  font-bold"
          onClick={handleComplete}
        >
          <IconCheck></IconCheck> {validateText}
        </button>
        <button
          className="line-clamp-1 h-8 text-nowrap bg-green-200 text-green-500 px-2 py-1 items-center flex gap-2 m-2 items-center jext-center justify-center  rounded -50  font-bold"
          onClick={handleCompleteManually}
        >
          <IconCheck></IconCheck> Manually validated
        </button>
        <button
          className=" line-clamp-1 h-8 text-nowrap bg-orange-200 text-orange-500 px-2 py-1 flex gap-2 m-2 items-center jext-center justify-center  rounded -50  font-bold"
          onClick={handleUndo}
        >
          <IconBackspace></IconBackspace> Undo
        </button>
        <button
          className=" line-clamp-1 h-8 text-nowrap bg-red-200 text-red-500 px-2 py-1 flex gap-2 m-2 items-center jext-center justify-center  rounded -50  font-bold"
          onClick={handleBug}
        >
          <IconBug></IconBug> Bug
        </button>
        <button
          className=" line-clamp-1 h-8 text-nowrap bg-blue-500 text-white px-2 py-1 flex gap-2 m-2 items-center jext-center justify-center  rounded -50  font-bold"
          onClick={handleSkip}
        >
          <IconArrowRight></IconArrowRight> Skip
        </button>
        {attachment && (
          <div className="flex items-center">
            <strong>Attachment:</strong>
            {/* "Up" button */}
            <button
              className="line-clamp-1 h-8 text-nowrap bg-blue-500 text-white px-2 py-1 flex gap-2 m-2 items-center jext-center justify-center  rounded -50  font-bold"
              onClick={() => handleAttachmentChange(-1)}
            >
              Prev
            </button>

            {/* Display current index out of total attachments */}
            <span>
              {props.attachmentIdx + 1}/{length}
            </span>

            {/* "Down" button */}
            <button
              className="line-clamp-1 h-8 text-nowrap bg-blue-500 text-white px-2 py-1 flex gap-2 m-2 items-center jext-center justify-center  rounded -50  font-bold"
              onClick={() => handleAttachmentChange(+1)}
            >
              Next
            </button>
          </div>
        )}
      </div>
      <div className="text-xs text-gray-500 border-none flex gap-1">
        <p>ID:</p>{" "}
        <input
          className="flex-grow"
          key={props.data?.id}
          defaultValue={props.data?.id}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // Call your onIdChange function here
              onIdChange(e.target.value); // Assuming onIdChange is your function and you want to pass the input's current value
            }
          }}
        />{" "}
      </div>
      <div
        className={`${
          props.transpose
            ? `flex flex-col flex-grow  justify-between ${
                props.dynamic && "xl:flex-row"
              }`
            : "flex flex-row"
        }`}
      >
        <div
          className={`flex flex-col justify-between ${
            props.transpose
              ? `h-64 ${props.dynamic && "xl:w-1/3 xl:h-full"} flex flex-col`
              : `w-1/3`
          }`}
        >
          {/* Render email text */}
          {props.data && (
            <>
              {" "}
              <div>
                <strong>Subject:</strong> {props.data.subject}
              </div>
              <div>
                <strong>Sender:</strong> {props.data.sender}
              </div>
              <div>{/* <strong>Date:</strong> {props.data.date} */}</div>
            </>
          )}
          <div>
            <strong>Email Content:</strong>
          </div>
          <textarea
            className={`flex-grow p-2 ${
              props.dynamic && "xl:m-0"
            } bg-gray-100 rounded m-2`}
            style={{ width: "100%" }}
            value={emailText}
          ></textarea>
          {/* Render attachment input */}
          {/* Render attachment if available */}
        </div>
        {attachment && (
          <div
            className={`${
              props.transpose
                ? `flex-grow ${props.dynamic && "xl:h-full"} flex`
                : "w-2/3"
            }`}
          >
            {/* Render attachment based on its type */}
            <div
              className={`${
                props.transpose
                  ? `flex-grow flex flex-col items-center ${
                      props.dynamic && "xl:h-full"
                    } `
                  : "w-2/3 "
              }`}
            >
              <h3 className=" w-full">
                <AttachmentName
                  key={JSON.stringify(
                    props.data.attachments[props.attachmentIdx].doctype
                  )}
                  data={props.data}
                  setData={props.setData}
                  attachmentId={props.attachmentIdx}
                  doctype={props.data.attachments[props.attachmentIdx].doctype}
                />
              </h3>
              {renderAttachment()}
              <a
                href={attachment}
                target="_blank"
                download
                className="download-button w-64 line-clamp-1 h-8 text-nowrap bg-blue-500 text-white px-2 py-1 flex gap-2 m-2 items-center jext-center justify-center  rounded -50  font-bold"
              >
                <IconDownload />
                Download Attachment
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const AttachmentName = ({ data, attachmentId, setData }) => {
  const getAttachmentType = (attachment) => {
    if (attachment.doctype) return attachment.doctype;
    if (attachment.user_rows) return "User";
    if (attachment.business_rows) return "Business";
    return "Other";
  };

  const getBusinessOrUserRows = () => {
    const userRows = data["all_user_rows"];
    const businessRows = data["all_business_rows"];

    // Check if userRows exists and has length greater than 0
    if (Array.isArray(userRows) && userRows.length > 0) {
      return userRows;
    }

    // Check if businessRows exists and has length greater than 0
    if (Array.isArray(businessRows) && businessRows.length > 0) {
      return businessRows;
    }

    // Return an empty array if neither condition is met
    return [];
  };

  const generatePDFName = (name, type, id) => {
    let typeString = "";
    if (type === "User") typeString = "user";
    else if (type === "Business") typeString = "convention";
    else if (type === "Business & User") typeString = "convention_user"; // Handling "Business & User" case

    return `${name}_${typeString}_${id}.pdf`;
  };

  const currentAttachment = data.attachments[attachmentId];
  const attachmentType = getAttachmentType(currentAttachment);
  const rows = getBusinessOrUserRows(currentAttachment, attachmentType);
  const name =
    rows.length > 0
      ? rows[0]["Organisation"]
      : `No ${attachmentType} rows to read filename from`;
  const pdfName = generatePDFName(name, attachmentType, attachmentId);

  const [localAttNameState, setLocalAttNameState] = useState(
    data.attachments[attachmentId].renamed ||
      data.attachments[attachmentId].rename ||
      pdfName
  );

  useEffect(() => {
    setLocalAttNameState(
      data.attachments[attachmentId].renamed ||
        data.attachments[attachmentId].rename ||
        pdfName
    );
  }, [attachmentId, data, pdfName]);

  const onChangePDFType = (e) => {
    const newData = { ...data };
    newData.attachments[attachmentId].doctype = e.target.value;
    setData(newData);

    // Update the PDF name based on the new type
    const newPdfName = generatePDFName(name, e.target.value, attachmentId);
    setLocalAttNameState(newPdfName);
  };

  const onChangePDFName = (e) => {
    data.attachments[attachmentId].renamed = e.target.value;
    setLocalAttNameState(e.target.value);
  };

  useEffect(() => {
    // Code here will run only on the initial mount of the component
    initialSetup();
  }, []); // Empty dependency array means this runs once on mount and never again

  const initialSetup = () => {
    // Your initial setup logic here
    data.attachments[attachmentId].renamed = pdfName;
  };

  const isValidName = ![
    "No User rows to read filename from",
    "No Business rows to read filename from",
    "No Other rows to read filename from",
  ].some((invalidName) => localAttNameState.includes(invalidName));

  return (
    <div className="flex gap-1 items-center w-full">
      <select
        size="xs"
        aria-label="Document Type"
        value={attachmentType} // Changed to controlled component
        onChange={onChangePDFType}
      >
        {["Business", "User", "Other", "Business & User"].map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </select>
      <div
        className={`text-sm font-bold text-white rounded px-2 py-1 ${
          isValidName ? "bg-green-400" : "bg-red-400"
        }`}
      >
        {isValidName ? "Valid" : "Not Valid"}
      </div>
      <input
        className="border text-gray-600 text-sm w-full"
        value={localAttNameState} // Changed to controlled component
        onChange={onChangePDFName}
      />
    </div>
  );
};

export default Antoine;
