import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  query,
  where,
  orderBy,
  limit,
  updateDoc,
  doc,
  serverTimestamp,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { IconClock } from "@tabler/icons-react";

export function ProcessTimeVisualizer(props) {
  const collectionName =
    process.env.REACT_APP_COLLECTION || "processed_data_prod_v5";
  const [allProcessTimes, setAllProcessTimes] = useState([]);
  useEffect(() => {
    const fetchDistribution = async () => {
      try {
        const q = query(collection(props.firestore, collectionName));
        const querySnapshot = await getDocs(q);

        // Count occurrences of each state
        const processTimes = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const startTime = data.inprogress_start_time;
          const endTime = data.inprogress_end_time;
          if (startTime && endTime) {
            const processTime = endTime - startTime;
            if (processTime < 1200 && processTime > 2) {
              //if processing took longer than 20 minutes it probably was opened but never processed
              processTimes.push(processTime);
            }
          }
        });
        setAllProcessTimes(processTimes);
        console.log(processTimes, "processTimes");
      } catch (error) {
        console.error("Error fetching distribution:", error);
      }
    };

    fetchDistribution();
  }, [props.firestore]);

  return (
    <div>
      <span className="font-bold text-lg flex gap-1 mt-12">
        Average time to process mail:{" "}
      </span>
      <div className="flex gap-1 bg-gray-200 text-gray-600 px-2 rounded-xl font-bold">
        <IconClock />{" "}
        {allProcessTimes &&
          allProcessTimes.length &&
          Math.round(
            (allProcessTimes.reduce((a, b) => a + b) / allProcessTimes.length) *
              10
          ) / 10}{" "}
        seconds
      </div>
    </div>
  );
}
